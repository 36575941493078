<template>
    <div>
      <div class="header">
        <van-nav-bar
          :title="$t('deposit.financial')"
          left-arrow
          @click-left="$router.go('-1')"
          @click-right="$router.push('/trade/shopping/trading')"
        />
      </div>
      <div class="maincontent">
        <van-tabs color="#004ea3">
            <van-tab :title="$t('deposit.financial')">
                <div class="card-items" 
                v-for="(item, index) in list"
                :key="item.id"
                >
                    <div class="left">
                        <van-image width="6rem" height="6rem" :src="require('../../assets/img/f'+item.id+'-icon.png')" />
                    </div>
                    <div class="right">
                        <div class="rate-info">
                            <span class="rate">{{item.max_rate}}%</span>
                            <span class="text">{{ $t('存币周期') }} {{item.day}} {{ $t('天') }}</span>
                        </div>
                        <div class="profit">
                            <span class="rate">{{ $t('收益条件') }}</span>
                            <span class="border-text" style="margin-right:5px">{{ $t('金额') }} {{item.min_money}}~{{item.max_money}}</span>
                            <span class="border-text">{{ $t('周期') }} {{item.day}}</span>
                        </div>
                        <div class="button">
                            <button class="lc-btn" @click="openshop(item)">{{ $t('买入') }}</button>
                        </div>
                    </div>
                </div>
            </van-tab>
            <van-tab :title="$t('deposit.order')">
                <van-list
                v-if="orderlist.length"
                v-model="loading"
                :finished="finished"
                :finished-text="$t('common.nomore')"
              >
                <van-row
                  class="currency-Increase"
                  v-for="item in orderlist"
                  :key="item.id"
                >
                  <van-col
                    :span="12"
                    style="text-align:left"
                  >
                    <span>{{ $t('订单号')}}：{{ item.order_no }}</span>
                    <br />
                    <span style="color:reg(128,128,128)">
                        {{ $t('存币数量')}} : <span style="color: rgb(128, 128, 128);">{{ item.money }}</span>
                    </span>
                    <br/>
                    <span style="color:reg(128,128,128)">
                        {{ $t('日收益')}} : <span>{{ item.day_interest }}</span>
                    </span>
                    <br />
                    <span style="color:reg(128,128,128)">
                        {{ $t('预期收益')}} : <span>{{ item.total_interest }}</span>
                    </span>
                    <br />
                    <span style="color:reg(128,128,128)">
                        {{ $t('已收益')}} : <span>{{ item.issued }}</span>
                    </span>
                    <br />
                    <span style="color:reg(128,128,128)">
                        {{ $t('存币周期')}} : <span>{{ item.day }}</span>
                    </span>
                    <br />
                    <span style="color:reg(128,128,128)">
                        {{ $t('状态')}} : <span v-if="item.state == 1" style="color:#004ea3">{{$t('进行中')}}</span><span v-if="item.state != 1" style="color:red">{{$t('已结束')}}</span>
                    </span>
                  </van-col>
                  <van-col
                    :span="12"
                    class="currency-time"
                  >
                    
                  </van-col>
                </van-row>
              </van-list>
              <van-empty
                :description="$t('common.nomore')"
                v-else
              />
            </van-tab>
            <van-tab :title="$t('deposit.gz')">
                <div class="gz-content">
                    <h3>{{$t('什麽是理財存幣生息？')}}</h3>
                    <p>{{$t('存幣生息是壹款幫助有空閑數字資產和有借幣需求的用戶之間對接的產品。依托嚴格的風控體系,充分保障用戶交易安全。')}}</p>
                    <p>{{$t('*請註意:歷史收益不能代表未來收益。我們不承諾在壹定期限內以貨幣、實物、股權等方式還本付息或者給付任何其他形式的財產性收益回報。')}}</p>
                    <h3>{{$t('產品優勢:')}}</h3>
                    <p>{{$t('穩健型')}}</p>
                    <p>{{$t('依托嚴格的風控體系,充分保障用戶資產安全,安心享收益。')}}</p>
                    <h3>{{$t('收益從何而來?')}}</h3>
                    <p>{{$t('存幣生息將存款用戶的資金匹配給有借幣需求的用戶,從而使存款用戶獲得壹定的借幣利息。')}}</p>
                    <h3>{{$t('什麽時候發放利息?')}}</h3>
                    <p>{{$t('申購成功後用戶可在T+1天收到T~T+1天時利息。')}}</p>
                    <h3>{{$t('利息如何計算?')}}</h3>
                    <p>{{$t('例如：以第一檔位計算 500USDT*0.15%=0.75USDT 這是每天的收益。')}}</p>
                    <h3>{{$t('贖回什麽時候能到賬?')}}</h3>
                    <p>{{$t('週期內不能手動操作贖回，需要等待週期到自動贖回後均可實時到賬。')}}</p>
                </div>
            </van-tab>
        </van-tabs>
        <!-- 购买弹出层 -->
        <van-dialog :confirmButtonText="$t('确认')" :cancelButtonText="$t('取消')" v-model="isshow" title="" width="90%" show-cancel-button confirmButtonColor="#28b48d" @confirm="submit">
            <div class="content">
                <div class="desc-balance"><span>{{ $t('可用余额') }}:</span><span class="symbol">{{balance.usable}} USDT</span></div>
                <div class="desc-2">{{ $t('存币周期') }}{{items.day}}{{ $t('天') }}</div>
                <van-cell-group inset>
                    <van-field class="bg-s" v-model="shop_number" :label="$t('存币数量')+':'" :placeholder="$t('请输入存币数量')" type="number">
                    <template #right-icon>
                        USDT
                    </template>
                    </van-field>
                </van-cell-group> 
                <div class="tips">{{$t('限额')}} {{items.min_money}}~{{items.max_money}} <span class="symbol">USDT</span></div>
                <van-cell-group inset>
                    <van-field :label="$t('收益率')" :value="items.max_rate+'%'" readonly />
                    <van-field :label="$t('预期收益')" :value="getProfit('total')" readonly  />
                    <van-field :label="$t('日收益')" :value="getProfit('day')"  readonly />
                    <van-field :label="$t('起息日期')" :value="getCurrentDate()"  readonly />
                    <van-field :label="$t('计息结束日')" :value="stopDate()"  readonly />
                    <van-field :label="$t('类型')" :value="$t('直接存入')"  readonly />
                </van-cell-group>
            </div>
        </van-dialog>
      </div>
    </div>
  </template>
  <script>
  export default {
    data() {
      return {
        buylist: [],
        isshow: false,
        loading:false,
        finished:true,
        time: 60 * 1000,
        buynum: '',
        index: 0,
        buyid: '',
        oauth: '',
        list:[
            {
                id:1,
                min_rate:"0.15",
                max_rate:"0.15",
                day:"5",
                min_money:"500",
                max_money:"9999999"
            },
            {
                id:2,
                min_rate:"0.15",
                max_rate:"0.17",
                day:"15",
                min_money:"10000",
                max_money:"9999999"
            },
            {
                id:3,
                min_rate:"0.18",
                max_rate:"0.21",
                day:"30",
                min_money:"30000",
                max_money:"9999999"
            },
            {
                id:4,
                min_rate:"0.22",
                max_rate:"0.25",
                day:"60",
                min_money:"50000",
                max_money:"9999999"
            },
            {
                id:5,
                min_rate:"0.25",
                max_rate:"0.3",
                day:"90",
                min_money:"100000",
                max_money:"9999999"
            },
        ],
        shop_number:0,
        items:{
            id:1,
            min_rate:"0.15",
            max_rate:"0.15",
            day:"5",
            min_money:"500",
            max_money:"9999999"
        },
        balance:{
            usd:0
        },
        orderlist:[]
      }
    },
    created() {
        this.getUserinfo();
        this.getOrder();
    },
    methods: {
        //获取订单记录
        async getOrder(){
            const { data } = await this.$http.get('/home/trade/getfinancial')
            if (data) {
                if (data.code === 200) {
                    this.orderlist = data.data
                }
            }
        },
        async submit(){
            if(parseFloat(this.shop_number) < parseFloat(this.items.min_money)){
                return this.$toast.fail(this.$t("存入金额不得低于最低金额"));
            }
            const { data } = await this.$http.post('/home/trade/financial_buy',{id:this.items.id,shop_number:this.shop_number})
            if (data) {
                if (data.code === 200) {
                    this.$toast.success(this.$t('common.success'))
                    this.getUserinfo();
                    this.getOrder();
                } else {
                    this.$toast.fail(this.$t(data.msg))
                }
            }
        },
        async getUserinfo(){
            const { data } = await this.$http.get('/home/user/index')
            if (data) {
                if (data.code === 200) {
                    this.balance = data.data.balance;
                }
            }
        },
        openshop(item){
            this.items  = JSON.parse(JSON.stringify(item));
            this.shop_number = this.items.min_money;
            this.isshow = true;
        },
        loadProfit(number){
            // if(number >= 9999999){
            //     this.shop_number = number;
            //     return false;
            // }
            console.log(number)
        },
        getProfit(type){
            var number = this.shop_number == "" || parseFloat(this.shop_number) <= 0 || parseFloat(this.shop_number) < parseFloat(this.items.min_money) ? this.items.min_money : this.shop_number;
            if(number >= parseFloat(this.items.max_money)){
                number = this.items.max_money;
                this.shop_number = number;
            }
            var dayProfit = (parseFloat(number) * parseFloat(this.items.max_rate) / 100).toFixed(2);
            if(type == "day"){
                return dayProfit;
            }else if(type == "total"){
                return parseFloat(dayProfit * this.items.day).toFixed(2);
            }
            //console.log(this.items.shop_number)
        },
        getCurrentDate(){
            const now = new Date(Date.now() + 86400000);
            const year = now.getFullYear();
            const month = ('0' + (now.getMonth() + 1)).slice(-2);
            const day = ('0' + now.getDate()).slice(-2);

            return day+"/"+month+"/"+year;
        },
        stopDate(){
            const now = new Date(Date.now() + (86400000 * parseFloat(this.items.day) ));
            const year = now.getFullYear();
            const month = ('0' + (now.getMonth() + 1)).slice(-2);
            const day = ('0' + now.getDate()).slice(-2);

            return day+"/"+month+"/"+year;
        }
    }
  }
  </script>
  <style lang="less" scoped>
  .header {
    /deep/.van-nav-bar__title {
      font-size: 1rem;
    }
    /deep/.van-nav-bar__text {
      font-size: 0.98667rem;
      color: #2c3e50;
    }
  }
  .gz-content{
    margin: .5rem;
    background: #fff;
    border-radius: 0.5rem;
    text-align:left;
    padding:1rem;
    h3{
        margin-bottom:.8rem
    }
    p{
        margin-bottom:.3rem
    }
  }
  .maincontent {
    padding: 3rem 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #f1f1f1;
  height: 100vh;
  /deep/.van-tab {
    color: grey;
    font-size: 1.04rem;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
}
.lc-btn{
    width:100%;
    border:none;
    color:#28b48d;
    font-weight:bold;
    padding:.4rem;
    line-height:1.5rem;
    border-radius:1rem;
}
.border-text{
    border:1px solid #ccc;
    padding:.3rem;
    color:#7b7d8c;
    border-radius:.3rem;
    font-size:.8rem;
}
.currency-Increase{
    margin: .5rem;
    background: #fff;
    border-radius: 0.5rem;
    padding:.7rem;
}
  .card-items{
    display: flex;
    margin: .5rem;
    background: #fff;
    border-radius: 0.5rem;
    padding:.7rem;
    align-items: center;
    .right{
        text-align: left;
        .rate-info{
            margin-bottom:.7rem;
            .rate{
                font-size:1.5rem;
                color:#28b48d;
                font-weight:bold;
                width: 6.8rem;
                display: inline-block;
            }
            .text{
                color:#52525e;
                font-weight:bold
            }
        }
        .profit{
            margin-bottom:1rem;
            .rate{
                color:#8d8d8d;
                font-size:.8rem;
                margin-right:.5rem;
            }
        }
    }
  }
  .bg-s{
    /deep/.van-field__value{
        background: #f5f5f5;
        padding: 3px;
    }
  }
  .van-dialog__content{
    .content{
        padding-top:1rem;
        padding-bottom:1rem;
        font-size:.9rem;
        .desc-balance{
            font-size:.9rem;
            margin-bottom:.5rem;
            .symbol{
                font-size:1rem
            }
        }
        .desc-2{
            font-size:.9rem;
        }
        .tips{
            .symbol{
                font-size:1rem
            }
        }
        .van-cell-group--inset{
            margin:0 auto;
        }
        /deep/.van-cell::after{
            display:none
        }
        /deep/.van-hairline--top-bottom::after{
            display:none
        }
    }
  }
  </style>
  